(function($) {

  var isMenuOpen = false;

  $('.site-header__menu').on('click', toggleMenu);

  function toggleMenu() {
    var $header = $(".site-mobile-header");
    isMenuOpen = !isMenuOpen;

    if (isMenuOpen) {
      var basicHeight = $(".site-header").outerHeight();
      $header.removeClass('global__hidden');
      $(".site-header").addClass('is-full-height');
      $(".site-header__menu__open").attr('class', 'global__hidden site-header__menu__open');
      $(".site-header__menu__close").attr('class', 'site-header__menu__close');
    } else {
      $header.addClass('global__hidden');
      $(".site-header").removeClass('is-full-height');
      $(".site-header__menu__open").attr('class', 'site-header__menu__open');
      $(".site-header__menu__close").attr('class', 'global__hidden site-header__menu__close');
    }
  }

  if (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0)) {
    $('body').removeClass('has-hover');
    $('.sc-post').bind('touchstart', function(e) {
      if (!$(this).hasClass('is-hovered')) {
        $('.sc-post').removeClass('is-hovered');
        $(this).addClass('is-hovered');
        // e.preventDefault();
        e.stopPropagation();
        return;
      }
    });
  }

})(jQuery);